import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import * as VerifyCheckStore from "../store/VerifyCheck";
import { ApplicationState } from '../store';

// At runtime, Redux will merge together...
type VerifyCheckProps =
    VerifyCheckStore.IVerifyCheckState // ... state we've requested from the Redux store
    //& typeof VerifyCheckStore.actionCreators
    //& { submit: any }; // ... plus action creators we've requested

//<div >{this.props.processState}</div>
//<div >{this.props.creditUnion.name}</div>

class NavMenu extends React.PureComponent<VerifyCheckProps, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        var cuLogoStyle = {
            backgroundImage: "url(data:image/svg+xml;base64," + this.props.creditUnion.logoImageBase64 + ")"
        };

        return (
            <header>
                
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><div className="vmacLogo" ></div></NavbarBrand>
                        <NavbarBrand tag={Link} to="/"><div className="cuLogo" style={cuLogoStyle}> </div></NavbarBrand>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect((state: ApplicationState) => state.verifyCheck)(NavMenu as any)


/*
 <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
 */
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import VerifyCheckMaster from "./VerifyCheckMaster"
import { ApplicationState } from "../store/index";
import { Spinner } from 'reactstrap';

const Home = () => {
    const verifyCheck = useSelector((state: ApplicationState) => state.verifyCheck);

    return <div>
        {verifyCheck?.isLoading ?? null ? <Spinner id="loader" animation="border" /> : null}
            <VerifyCheckMaster />
          </div>;
};

export default connect()(Home);
